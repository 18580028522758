import { createRouter, createWebHistory } from "vue-router";
import Home from "../components/TheLandingpage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Home | modern art ensemble",
      metaTags: [
        {
          name: "description",
          content:
            "Willkommen auf der Webseite des modern art ensembles. Hier finden Sie alle Informationen zu unseren bevorstehenden Konzerten.",
        },
      ],
    },
    component: Home,
  },
  {
    path: "/bio",
    name: "Bio",
    meta: {
      title: "Biographien | modern art ensemble",
      metaTags: [
        {
          name: "description",
          content:
            "Kurzbiographien aller Mitglieder des modern art ensembles in deutsch und englisch finden Sie hier.",
        },
      ],
    },
    component: () => import("../components/TheBiography"),
  },
  {
    path: "/search",
    name: "Search",
    meta: {
      title: "Archiv | modern art ensemble",
      metaTags: [
        {
          name: "description",
          content:
            "Alle Informationen zu unseren gespielten Konzerten können hier angesehen werden. Alle Uraufführungen und Komponisten können ebenfalls durchsucht werden.",
        },
      ],
    },
    component: () => import("../components/TheArchive"),
  },
  {
    path: "/cds",
    name: "CDs",
    meta: {
      title: "CDs | modern art ensemble",
      metaTags: [
        {
          name: "description",
          content:
            "Eine vollständige Liste aller veröffentlichten CDs des modern art ensembles.",
        },
      ],
    },
    component: () => import("../components/TheCDs"),
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () => import("../components/TheImpressum"),
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: () => import("../components/TheDatenschutz"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else {
    // ← here ↓
    document.title = previousNearestWithMeta.meta.title;
  }

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
