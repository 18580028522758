import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
require("./css/normalize.min.css");
require("./css/mui.min.css");
require("./css/styles.css");
//require("./js/mui.min.js")

const app = createApp(App).use(router);


app.mount("#app");
