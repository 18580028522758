<template>
  <div id="vue-instance" class="app-container">
    <nav id="navbar">
      <ul class="topmenu">
        <li class="logo">
          <router-link to="/">
            <img src="/assets/MAE-Logo.jpeg" />
          </router-link>
        </li>
        <li class="item">
          <router-link to="/"> Home </router-link>
        </li>
        <li class="item">
          <router-link to="/bio"> Das Ensemble </router-link>
        </li>
        <li class="item">
          <router-link to="/search"> Archiv </router-link>
        </li>
        <li class="item">
          <router-link to="/cds"> CDs </router-link>
        </li>
      </ul>
      <div class="row mobile-menu">
        <input id="hamburg" type="checkbox" />
        <label for="hamburg" class="hamburg">
          <span class="line" />
          <span class="line" />
          <span class="line" />
        </label>

        <nav class="topmenu">
          <ul>
            <li>
              <router-link to="/"> Home </router-link>
            </li>
            <li>
              <router-link to="/bio"> Das Ensemble </router-link>
            </li>
            <li>
              <router-link to="/search"> Archiv </router-link>
            </li>
            <li>
              <router-link to="/cds"> CDs </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </nav>

    <router-view class="main-content" />

    <footer class="footer-wrapper mui--text-light">
      <div class="footer-container mui-row mui--text-center">
        <div class="footer-column mui-col-md-4">
          <ul>
            <li>© {{ currentDate }} modern art ensemble</li>
            <li>
              <a href="mailto:info@modern-art-ensemble.de"
                >info@modern-art-ensemble.de</a
              >
            </li>
          </ul>
        </div>
        <div class="footer-column mui-col-md-4">
          <ul>
            <li>
              <router-link to="/datenschutz">
                Datenschutzerklärung
              </router-link>
            </li>
            <li>
              <router-link to="/impressum"> Impressum </router-link>
            </li>
          </ul>
        </div>
        <div class="footer-column mui-col-md-4">
          <ul>
            <li>
              <a href="http://eepurl.com/hT6Xd9">Newsletter abbonieren</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Willkommen",
      // all titles will be injected into this template
      titleTemplate: "%s | modern art ensemble",
    };
  },
  data() {
    return {};
  },
  computed: {
    currentDate() {
      let d = new Date();
      let yyyy = d.getFullYear();
      return yyyy;
    },
  },
  mounted() {},
};
</script>
<style></style>
