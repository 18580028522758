<template>
  <div class="mui-container">
    <div class="paragraphs-wrapper">
      <div class="mui-container">
        <img
          class="responsive"
          alt="Foto der gesamten Besetzung des modern art ensembles"
          src="/assets/modern_art_ensemble.jpg"
        />
      </div>
      <div class="mui-container newsletter-look">
        <div class="mui--text-display1 margin-t margin-b mui--text-dark">
          Konzerte:
        </div>
        <div class="margin-t-b">
          <div class="date mui--text-title mui--text-accent">
            Freitag, 15.12.2023, 20:00 Uhr<br />
          </div>
          <div class="title margin-t-s mui--text-title mui--text-dark">
            <b>Polarities</b>
            <br />
            Ursula und Dwight Mamlok @ 100
          </div>
          <div class="margin-t-s relaxed-letter-spacing">
            Saitenflügel-Konzertsaal im Künstlerhof Alt-Lietzow<br />
            Alt-Lietzow 12<br />
            10587 Berlin <br /><br />
          </div>
          <div class="margin-t-b relaxed-letter-spacing">
            <div>
              <div>
                <img
                  class="responsive"
                  alt="grafik des flyers zum Konzert des modern art ensembles"
                  src="/assets/newsletter/POSTKARTE_Polarities.jpg"
                />
              </div>
              <div class="mui--text-caption margin-b-l mui--text-right">
                Klaudia Stoll
              </div>
              <div class="body relaxed-letter-spacing">
                <p class="margin-b-m"><b>Programm:</b></p>

                <p class="margin-t-s">
                  <b>Klaus Schöpp</b><br />
                  fluency shaping, in memoriam Dwight Mamlok (2023, Uraufführung)<br />
                  (Flöte, Violine und Violoncello)
                </p>
                <p class="margin-t-s">
                  <b>Ursula Mamlok</b><br />
                  Variations (1961)<br />
                  (Flöte solo)
                </p>
                <p class="margin-t-s">
                  <b>Jürgen Kupfer</b><br />
                  Klaviertrio (1971)<br />
                </p>
                <p class="margin-t-s">Pause</p>
                <p class="margin-t-s">
                  <b>Ursula Mamlok</b><br />
                  Terzianum (2006)<br />
                  (Flöte, Violine)
                </p>
                <p class="margin-t-s">
                  <b>Paulo Lima</b><br />
                  Yemanjá (2023; europäische Erstaufführung)<br />
                  (Violoncello solo)
                </p>
                <p class="margin-t-s">
                  <b>Ursula Mamlok</b><br />
                  Polarities (1995)<br />
                  (Flöte, Violine, Violoncello, Klavier)
                </p>
              </div>
            </div>
          </div>
          <div class="body margin-t-m relaxed-letter-spacing">
            <div class="margin-t-b">
              <p><b>Mitwirkende:</b></p>
            </div>
            <div class="margin-t-s">
              <p><b>modern art ensemble</b></p>
              <ul>
                <li>Klaus Schöpp Flöte</li>
                <li>Theodor Flindell Violine</li>
                <li>Matias de Oliveira Pinto Violoncello</li>
                <li>Yoriko Ikeya Klavier</li>
              </ul>
            </div>
            <div class="margin-t">
              Das erste Stück des Abends, Klaus Schöpps „fluency shaping“ ist Dwight Mamlok gewidmet und bezieht sich auf seine (autobiographische) Erzählung „The harmony of the Universe“. Die Geschichte spielt 1938, der Sohn einer jüdischen Familie, dessen Vater von der Gestapo verhaftet wurde, wird von der Mutter aufgefordert, einen Grußtext für seinen Onkel in Amerika aufs Band zu sprechen, in der Hoffnung, von ihm die nötigen Mittel zur Ausreise aus Hamburg zu bekommen. Die Aufnahme misslingt, weil der Sohn sein Stottern nicht überwinden kann.<br /><br />

              Es folgt „Variations“ von Ursula Mamlok für Flöte solo, ein Stück aus dem Jahr 1961, virtuos und ganz im spätseriellen Stil der damaligen Zeit verfasst.<br /><br />

              Danach führt das Programm zum Klaviertrio des thüringischen Komponisten Jürgen Kupfer aus dem Jahr 1971, das ebenfalls den Stil seiner Zeit spiegelt, indem es die Moderne des Warschauer Herbstes zum Ausgangspunkt nimmt und Anklänge an Schostakowitsch, Schnittke und Lutoslawski besitzt.<br />

              Mit „Terzianum“ wird ein spätes Stück von Ursula Mamlok (aus dem Jahr 2006) aufgeführt, „Tertianum“ ist der Name ihres letzten Wohnsitzes und die Musik aus ihrem letzten Lebensabschnitt ist heiter und elegant.<br /><br />

              Es folgt die europäische Erstaufführung von „Yamanjá“ für Violoncello solo des brasilianischen Komponisten Paulo Costa Lima, der in Bahia lebt und sich intensiv mit der Musik der brasilianischen indigenen Kulturen befasst.<br /><br />

              Zum Abschluss: Ursula Mamloks „Polarities“ aus dem Jahr 1995. Ein virtuoses Ensemblestück, in dem wechselnde Tempi aus den Rhythmen der Figuren abgeleitet werden. Mit diesem Stück begann die Beschäftigung des modern art ensembles mit der Musik Ursula Mamloks, es hat „Polarities“ am 29. Februar 2000 in der Konzertreihe „musica reanimata“ in Anwesenheit von Ursula und Dwight Mamlok aufgeführt.<br /><br />
            </div>
            <div class="mui-row margin-b-xl">
              <div class="mui-col-md-12">
                <img
                  class="responsive"
                  alt="Logos der Förderer des modern art ensembles"
                  src="/assets/newsletter/Logo Mamlok-Stiftung.jpg"
                />
              </div>
            </div>
          </div>
        </div>
        <!--End of Concert-->
        <div class="margin-t-b">
          <div class="date mui--text-title mui--text-accent">
            15. November 2023, 20:00 Uhr<br />
          </div>
          <div class="title margin-t-s mui--text-title mui--text-dark">
            modern art ensemble<br />
            <b>Decay</b>
          </div>
          <div class="margin-t-s relaxed-letter-spacing">
            Konzerthaus Berlin<br />
            Werner-Otto-Saal<br />
            Gendarmenmarkt 2<br />
            10117 Berlin<br /><br />
          </div>
          <div class="margin-t-b relaxed-letter-spacing">
            <div>
              <div>
                <img
                  class="responsive"
                  alt="grafik des flyers zum Konzert des modern art ensembles"
                  src="/assets/newsletter/Grafik_Konzert_Decay.jpg"
                />
              </div>
              <div class="mui--text-caption margin-b-l mui--text-right">
                Klaudia Stoll
              </div>
              <div class="body relaxed-letter-spacing">
                <p class="margin-b-m"><b>Programm:</b></p>

                <p class="margin-t-s">
                  <b>Makiko Nishikaze</b><br />
                  shima-zima (Islands/Inseln) (2017/18) (Flöte, Klarinette, Violine, Viola, Violoncello, Klavier)
                </p>
                <p class="margin-t-s">
                  <b>Umberto Pedraglio</b><br />
                  Sulle tracce d’uno spettro (2020) (Streichtrio)
                </p>
                <p class="margin-t-s">
                  <b>Olga Neuwirth</b><br />
                  Verfremdung/Entfremdung (2002) (Flöte, Klavier, Elektronik)
                </p>
                <p class="margin-t-s">
                  <b>Kaspar Querfurth</b><br />
                  als wehte Wind UA (2023) (Flöte, Violine, Viola, Violoncello)
                </p>
                <p class="margin-t-s">
                  <b>Dieter Mack</b><br />
                  The shining of the abyss and the darkness of light (2020)
                  (Flöte, Klarinette, Violine, Violoncello, Klavier)
                </p>
              </div>
            </div>
          </div>
          <div class="body margin-t-m relaxed-letter-spacing">
            <div class="margin-t-b">
              <p><b>Mitwirkende:</b></p>
            </div>
            <div class="margin-t-s">
              <p><b>modern art ensemble</b></p>
              <ul>
                <li>Klaus Schöpp Flöte</li>
                <li>Matthias Badczong Klarinette</li>
                <li>Marika Ikeya Violine</li>
                <li>Martin Flade Viola</li>
                <li>Matias de Oliveira Pinto Violoncello</li>
                <li>Yoriko Ikeya Tafelklavier</li>
              </ul>
            </div>
            <div class="margin-b-xl">
              Das modern art ensemble präsentiert aktuelle Musik unserer Gegenwart: Zerrissen, gespannt, experimentell, auf der Suche nach ungehörter Schönheit.<br /><br />

              Es erklingt: „Shima-Zima – Islands/Inseln“ von Makiko Nishikaze. Das Stück reflektiert mit den im Raum aufgestellten MusikerInnen auf eigenwillige Weise das Leben in einer Inselwelt. Olga Neuwirths „Verfremdung/Entfremdung“ spannt Flöten- und Klavierklänge bis fast zum Zerreißen. Die Uraufführung von Kaspar Querfurths „als wehte Wind“ und Umberto Pedraglios „Sulle tracce d’uno spettro“, sind ungemein detailliert ausgearbeitete Klangwunder. Zum Abschluss: Dieter Macks “The shining of the abyss and the darkness of light”. Der paradoxe Titel verweist darauf, dass der Abgrund zwar eine Welt darstellt, in die man eintauchen kann, ohne zu wissen, wo er endet, aber dass man auch aus ihm aufsteigen kann, in den Glanz, in eine alles erhellende Klarheit. Dagegen scheint das Licht auf den ersten Blick hell und schön zu sein, aber es kann auch blenden und zerstören. Der Titel ist also weniger ein Paradoxon als vielmehr die Beschreibung der Ambivalenz, in der wir leben.
            </div>
          </div>
        </div>
        <!--End of Concert-->
        <div class="margin-t-b">
          <div class="date mui--text-title mui--text-accent">
            Freitag, 16.06.2023, 19:00 Uhr<br />
          </div>
          <div class="title margin-t-s mui--text-title mui--text-dark">
            Das Konzert findet statt im Rahmen des Festivals für Neue Musik der Kreismusikschule Potsdam-Mittelmark in Kleinmachnow:<br />
            „Horizont der Klänge“
          </div>
          <div class="margin-t-s relaxed-letter-spacing">
            Kreismusikschule Potsdam-Mittelmark Engelbert Humperdinck<br />
            Studio 10<br />
            Am Weinberg 18, 14532 Kleinmachnow<br /><br />
          </div>
          <div class="margin-t-b relaxed-letter-spacing">
            <div>
              <div>
                <img
                  class="responsive"
                  alt="grafik des flyers zum Konzert des modern art ensembles"
                  src="/assets/newsletter/Grafik_Horizont_der_Klänge.jpg"
                />
              </div>
              <div class="body relaxed-letter-spacing">
                <p class="margin-b-m"><b>Programm:</b></p>

                <p class="margin-t-s">
                  <b>Daniel Martinez Roura (*1981)</b><br />

                  Sólidoliquido (2014)
                  (Flöte, Klarinette, Violine, Viola, Violoncello, Klavier)
                </p>
                <p class="margin-t-s">
                  <b>Eres Holz (*1977)</b><br />

                  Mach (2011)
                  (Trompete solo)
                </p>
                <p class="margin-t-s">
                  <b>Ursula Mamlok (1923-2016)</b><br />

                  When Summer Sang (1980)
                  (Flöte, Klarinette, Violine, Violoncello, Klavier)
                </p>
                <p class="margin-t-s">
                  <b>Nico Muhly (*1981)</b><br />

                  Balance Problems (2013)
                  (Trompete, Flöte, Klarinette, Violine, Viola, Violoncello)
                </p>
                <p class="margin-t-s">
                  <b>Sidney Corbett (*1960)</b><br />

                  Aus: Piano Valentines Band III (2021)
                  (Klavier solo)<br />

                  Nr. 17 in memoriam Pina Bausch<br />

                  Nr. 18 in memoriam Anna Stepanovna Politkovskaya<br />

                  Nr. 19 „Vox legis“ in memoriam Ruth Bader Ginsburg<br />
                </p>
                <p class="margin-t-s">
                  <b>Stefan Lienenkämper (*1963)</b><br />

                  numbers/…soup of time (2020/23, Uraufführung)
                  (Flöte, Klarinette, Violine, Viola, Violoncello, Klavier)
                </p>
              </div>
            </div>
          </div>
          <div class="body margin-t-m relaxed-letter-spacing">
            <div class="margin-t-b">
              <p><b>Mitwirkende:</b></p>
              <ul>
                <li>Damir Bacikin, Trompete</li>
                <li>Uli Aumüller, Video</li>
              </ul>
            </div>
            <div class="margin-t-s">
              <p><b>modern art ensemble</b></p>
              <ul>
                <li>Klaus Schöpp Flöte</li>
                <li>Matthias Badczongg Klarinette</li>
                <li>Theodor Flindell Violine</li>
                <li>Nikolaus Schlierf Viola</li>
                <li>Matias de Oliveira Pinto Violoncello</li>
                <li>Yoriko Ikeya Klavier</li>
              </ul>
            </div>
            <div class="margin-t-b">
              Das modern art ensemble präsentiert in seinem Konzert im Rahmen des Festivals „Horizont der Klänge“ ein berlinerisch-amerikanisches Programm, mit Werken sowohl der jüngeren Komponistengeneration als auch Musik der New Yorker Berliner Ursula Mamlok, die in diesen Tagen hundert Jahre alt geworden wäre. Wir freuen auf die Uraufführung von Stefan Lienenkämpers “numbers/…soup of time“, geschrieben für das modern art ensemble und auf die Solostücke von Sidney Corbett, im Andenken an großen Frauen unserer Zeit: Pina Bausch, Anna Stepanovna Politkovskaja und Ruth Bader Ginsburg, und von Eres Holz, gespielt von unseren wunderbaren Solisten Yoriko Ikeya und Damir Bacikin.
              <br /><br />
             
            </div>
          </div>
        </div>
        <!--End of Concert-->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASEURL, // API url
    };
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
